/* src/styles/theme.css */

:root {
  --color-primary: #EED5BA;
  --color-primary-rgb: 238, 213, 186;
  --color-secondary: #42A5F5;
  --color-secondary-rgb: 66, 165, 245;
  --color-accent: #0078D4;
  --color-accent-rgb: 0, 120, 212;
  --color-accent-light: #80D8FF;
  --color-background: #EED5BA;
  --color-text: #3D2B00;
  --color-text-rgb: 61, 43, 0;
  --toolbar-scrolled-bg-color: rgba(238, 213, 186, 0.1);
  --shadow-sm: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  --shadow-md: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 
               0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 
               0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

body {
  background-color: var(--color-background);
  color: var(--color-text);
  transition: background-color 0.3s ease, color 0.3s ease;
}

body.dark {
  --color-primary: #2E3640;
  --color-primary-rgb: 46, 54, 64;
  --color-secondary: #42A5F5;
  --color-secondary-rgb: 66, 165, 245;
  --color-accent: #80D8FF;
  --color-accent-rgb: 128, 216, 255;
  --color-accent-light: #80D8FF;
  --color-background: #2E3640;
  --color-text: #E0E0E0;
  --color-text-rgb: 224, 224, 224;
  --toolbar-scrolled-bg-color: rgba(46, 54, 64, 0.8);
  --shadow-sm: 0 1px 2px 0 rgba(255, 255, 255, 0.05);
  --shadow-md: 0 4px 6px -1px rgba(255, 255, 255, 0.1), 
               0 2px 4px -1px rgba(255, 255, 255, 0.06);
  --shadow-lg: 0 10px 15px -3px rgba(255, 255, 255, 0.1), 
               0 4px 6px -2px rgba(255, 255, 255, 0.05);
}