/* src/components/PricingSection.css */
.pricing-section {
  padding: 3rem 0;
  width: 100%;
  background-color: var(--color-background);
  color: var(--color-text);
  scroll-margin-top: 80px;
}

.pricing-content {
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 1.5rem;
  text-align: center;
}

.pricing-headline {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
}

.pricing-cards {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  margin-bottom: 1.5rem;
}

.pricing-card {
  flex: 1;
  max-width: 450px;
  background-color: var(--color-background);
  border: 1px solid var(--color-text);
  border-radius: 10px;
  padding: 1.25rem;
  text-align: left;
  position: relative;
  box-shadow: var(--shadow-md);
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
}

.pricing-card:hover {
  transform: translateY(-3px);
  box-shadow: var(--shadow-lg);
}

.pricing-card.pro {
  border-color: var(--color-accent);
}

.early-bird {
  position: absolute;
  top: -10px; /* Adjust as needed */
  right: 5px;  /* Adjust as needed */
  background-color: var(--color-accent);
  color: white;
  font-size: 0.75rem;
  font-weight: 600;
  padding: 0.25rem 0.75rem;
  border-radius: 12px;
}

.card-header {
  border-bottom: 1px solid rgba(var(--color-text-rgb, 0, 0, 0), 0.1);
  padding-bottom: 0.75rem;
  margin-bottom: 1rem;
}

.tier-name {
  font-size: 1.2rem;
  font-weight: 700;
}

.price {
  font-size: 2rem;
  font-weight: 800;
  margin: 0.5rem 0 0.25rem;
  display: flex;
  align-items: baseline;
}

.lifetime-text {
  font-size: 1rem;
  font-weight: 600;
  color: var(--color-accent);
  margin-top: 0.25rem;
  display: block;
}

.price-subtitle {
  font-size: 0.9rem;
  opacity: 0.8;
  margin-bottom: 0.5rem;
}

.pricing-plans {
  margin-top: 0.5rem;
}

.pricing-option-header {
  font-weight: 600;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

.pricing-options {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-top: 0.25rem;
}

.pricing-option {
  flex: 1;
  padding: 0.75rem;
  border-radius: 8px;
  background-color: rgba(var(--color-text-rgb, 0, 0, 0), 0.04);
}

/* Add relative positioning for the specific buy-to-own option */
.buy-to-own-option {
  position: relative;
}

.option-name {
  font-weight: 600;
  font-size: 0.9rem;
  margin-bottom: 0.25rem;
}

.price-period {
  font-size: 1rem;
  font-weight: 400;
  margin-left: 0.25rem;
}

.price-details {
  font-size: 0.85rem;
  line-height: 1.4;
}

.regular-price {
  display: block;
  text-decoration: line-through;
  opacity: 0.8;
}

.promo {
  display: block;
  margin-bottom: 0.5rem;
}

.update-info, .update-renewal {
  margin-top: 0.25rem;
  font-size: 0.85rem;
  opacity: 0.9;
}

.features-intro {
  font-weight: 600;
  font-size: 0.9rem;
  margin-bottom: 0.75rem;
}

.feature-list {
  list-style: none;
  padding: 0;
  margin: 0 0 1rem 0;
  flex-grow: 1; /* Allows list to fill available space */
}

.feature-list li {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 0.85rem;
  font-size: 0.9rem;
  line-height: 1.4;
}

.check-icon {
  color: var(--color-accent);
  flex-shrink: 0;
  margin-top: 0.25rem;
}

.model-list {
  display: block;
  font-size: 0.8rem;
  opacity: 0.8;
  margin-top: 0.2rem;
}

.sub-features {
  list-style: none;
  padding: 0;
  margin: 0.3rem 0 0 1.25rem;
}

.sub-features li {
  margin-bottom: 0.2rem;
  position: relative;
  font-size: 0.8rem;
  opacity: 0.9;
}

.sub-features li:before {
  content: "•";
  position: absolute;
  left: -0.75rem;
}

.purchase-options {
  margin-top: auto; /* Push to bottom of container */
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.card-cta {
  display: block;
  text-align: center;
  text-decoration: none;
  font-weight: 600;
  padding: 0.6rem;
  border-radius: 6px;
  margin-top: 0.5rem;
  transition: all 0.2s ease;
}

.free-cta {
  color: var(--color-text);
  border: 1px solid var(--color-text);
}

.free-cta:hover {
  background-color: var(--color-text);
  color: var(--color-background);
}

.pro-cta {
  background-color: var(--color-accent);
  color: white;
}

.card-cta:hover {
  transform: translateY(-2px);
  box-shadow: 0 3px 8px rgba(var(--color-accent-rgb, 0, 120, 212), 0.3);
  color: white; /* Ensure text remains visible on hover */
}

/* Free tier enhancements */
.free-tier-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex-grow: 1; /* Allow content to fill available space */
}

.free-tier-highlights {
  display: flex;
  justify-content: space-between;
  margin: 0.5rem 0 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid rgba(var(--color-text-rgb, 0, 0, 0), 0.1);
}

.highlight-item {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 1rem 0.5rem;
  flex: 1;
  background-color: var(--color-background);
  border: 1px solid rgba(var(--color-accent-rgb, 0, 120, 212), 0.2);
  border-radius: 8px;
  margin: 0 0.25rem;
}

.highlight-text {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.2;
  color: var(--color-text);
}

.special-offers {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
}

.special-offers-title {
  margin-bottom: 1rem;
  font-size: 1.3rem;
  font-weight: 700;
  text-align: center;
}

.special-offers .contact-info {
  margin-top: 1rem;
  font-size: 0.9rem;
  text-align: center;
}

.special-offers .contact-info a {
  color: var(--color-accent);
  text-decoration: none;
  font-weight: 600;
}

.special-offers .contact-info a:hover {
  text-decoration: underline;
}

.special-offers .special-offer {
  width: 100%;
  max-width: 600px;
  padding: 1.25rem;
  border-radius: 10px;
  text-align: left;
  background-color: var(--color-background);
  box-shadow: var(--shadow-md);
  transition: transform 0.3s ease;
  margin-bottom: 1rem;
}

.special-offer:hover {
  transform: translateY(-3px);
  box-shadow: var(--shadow-lg);
}

.student-offer {
  border: 1px solid #4e7bff; /* Blue for students */
}

.team-offer {
  border: 1px solid var(--color-accent);
}

.special-offer h3 {
  margin-top: 0;
  margin-bottom: 0.75rem;
  font-size: 1.1rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.special-offer p {
  margin: 0;
  font-size: 0.9rem;
  line-height: 1.4;
}

.special-offer a {
  color: var(--color-accent);
  text-decoration: none;
}

.special-offer a:hover {
  text-decoration: underline;
}

@media (max-width: 900px) {
  .pricing-cards {
    flex-direction: column;
    align-items: center;
  }
  
  .pricing-card {
    width: 100%;
  }
  
  .special-offers .special-offer {
    width: 100%;
    max-width: 100%;
  }
  
  .pricing-options {
    gap: 0.75rem;
  }
  
  .pricing-option {
    padding: 0.5rem;
  }
  
  .free-tier-highlights {
    flex-wrap: wrap;
  }
  
  .highlight-item {
    min-width: 120px;
  }
}

@media (max-width: 600px) {
  .pricing-options {
    flex-direction: column;
  }
}