/* src/components/Roadmap.css */
.roadmap {
  padding: 3rem 0;
  background-color: var(--color-background);
  color: var(--color-text);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease, color 0.3s ease;
  scroll-margin-top: 80px;
}

.roadmap-content {
  max-width: 1100px;
  width: 100%;
  text-align: center;
  padding: 0 1.5rem;
}

.roadmap-title {
  font-size: 2.2rem;
  font-weight: 700;
  margin-bottom: 2rem;
  color: var(--color-text);
}

.roadmap-items {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
}

.roadmap-item {
  background-color: var(--color-background);
  border: 1px solid var(--color-text);
  border-radius: 8px;
  padding: 1.5rem;
  text-align: left;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  height: 100%;
  box-shadow: var(--shadow-md);
}

.roadmap-item:hover {
  transform: translateY(-5px);
  box-shadow: var(--shadow-lg);
}

.roadmap-item h3 {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 0.8rem;
  color: var(--color-text);
}

.roadmap-item p {
  color: var(--color-text);
  line-height: 1.5;
  opacity: 0.9;
}

/* Dark mode styles */
body.dark .roadmap {
  background-color: var(--color-background);
  color: var(--color-text);
}

body.dark .roadmap-item {
  background-color: var(--color-background);
  border-color: var(--color-text);
}

@media (max-width: 768px) {
  .roadmap {
    padding: 2rem 0;
  }
  
  .roadmap-title {
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
  }
  
  .roadmap-items {
    grid-template-columns: 1fr;
  }
}