/* src/components/Footer.css */
.footer {
  background: rgba(238, 213, 186, 0.1); /* Semi-transparent primary color background */
  backdrop-filter: blur(10px); /* Creates the frosted glass effect */
  -webkit-backdrop-filter: blur(10px); /* For Safari support */
  color: var(--color-text); /* Updated text color for light mode */
  padding: 0.75rem 0;
  width: 100%;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
  transition: background 0.3s ease;
  border-top: 1px solid rgba(238, 213, 186, 0.2); /* Subtle top border */
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

.footer-contact {
  color: var(--color-text);
  text-decoration: none;
  font-weight: 500;
  transition: border-bottom 0.2s ease;
  padding-bottom: 2px;
  border-bottom: 2px solid transparent;
}

.footer-contact:hover {
  border-bottom: 2px solid var(--color-text);
}

.footer-links {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.footer-link {
  color: var(--color-text);
  text-decoration: none;
  font-weight: 500;
  transition: border-bottom 0.2s ease;
  padding-bottom: 2px;
  border-bottom: 2px solid transparent;
}

.footer-link:hover {
  border-bottom: 2px solid var(--color-text);
}

.separator {
  color: var(--color-text);
}

body.dark .footer {
  background: rgba(46, 54, 64, 0.2); /* Semi-transparent dark mode background */
  color: #E0E0E0; /* Adjust text color for dark mode */
  border-top: 1px solid rgba(255, 255, 255, 0.1); /* Subtle top border for dark mode */
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    gap: 1rem;
    text-align: center;
  }

  .footer-links {
    margin-top: 0.5rem;
  }
}