/* src/components/Hero.css */

/* ================================
   Warning Banner Styles
   ================================ */
.warning-banner {
  display: flex;
  align-items: center;
  padding: 1rem 1.5rem;
  margin-bottom: 2rem;
  background-color: rgba(33, 150, 243, 0.15);
  border: 2px solid rgba(33, 150, 243, 0.5);
  border-radius: 8px;
  width: 100%;
  max-width: 900px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

body.dark .warning-banner {
  background-color: rgba(33, 150, 243, 0.12);
  border-color: rgba(33, 150, 243, 0.4);
}

.warning-icon {
  flex-shrink: 0;
  font-size: 1.4rem;
  color: #2196F3;
  margin-right: 1rem;
}

.warning-banner p {
  margin: 0;
  color: var(--color-text);
  font-weight: 500;
  line-height: 1.4;
}

@media (max-width: 768px) {
  .warning-banner {
    padding: 0.8rem 1rem;
    margin-bottom: 1.5rem;
    flex-direction: column;
    text-align: center;
  }
  
  .warning-icon {
    margin-right: 0;
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
  }
}

/* ================================
   Hero Section Styles
   ================================ */
   .hero {
    width: 100%;
    margin: 0;
    padding: 3rem 0;
    background: var(--color-primary);
    background-image: linear-gradient(
      to bottom,
      var(--color-primary),
      var(--color-primary) 85%,
      var(--color-background) 100%
    );
    position: relative;
    display: flex;
    justify-content: center;
    min-height: 100vh;
  }
  
  /* Container for all hero content */
  .hero-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  /* Logo Styling */
  .hero-logo-container {
    text-align: center;
    margin-bottom: 1.5rem;
    padding-top: 2rem;
  }
  
  .hero-logo {
    width: 200px;
    height: auto;
    filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.1));
    transition: transform 0.3s ease, filter 0.3s ease;
  }
  
  .hero-logo:hover {
    transform: scale(1.05);
    filter: drop-shadow(0 6px 8px rgba(0, 0, 0, 0.15));
  }
  
  /* Headline and Subtitle */
  .hero-headline {
    font-size: 3.5rem;
    font-weight: 800;
    color: var(--color-text);
    text-align: center;
    line-height: 1.2;
    letter-spacing: -0.5px;
    margin-bottom: 1rem;
    max-width: 900px;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.07);
  }
  
  .hero-subtitle {
    font-size: 1.5rem;
    color: var(--color-text);
    opacity: 0.8;
    margin-bottom: 2rem;
    max-width: 900px;
    text-align: center;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.07);
  }
  
  body.dark .hero-subtitle {
    color: var(--color-text);
  }
  
  /* Section Titles (for "Why", "What It Does", etc.) */
  .hero-section-title {
    font-size: 2rem;
    font-weight: 700;
    color: var(--color-text);
    text-align: center;
    margin-bottom: 2rem;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.07);
  }
  
  /* ================================
     CTA Button Container
     ================================ */
  .cta-container {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    margin-bottom: 3rem;
  }
  
  /* ================================
     CTA Buttons
     ================================ */
  .cta-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    color: var(--color-text);
    padding: 0.8rem 1.5rem;
    text-decoration: none;
    border: 2px solid var(--color-text);
    border-radius: 12px;
    font-weight: bold;
    transition: all 0.3s ease;
    cursor: pointer;
    min-width: 180px;
  }
  
  .cta-button:hover {
    background-color: var(--color-text);
    color: var(--color-primary);
    border-color: var(--color-text);
    transform: translateY(-2px);
  }
  
  .cta-button:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(var(--color-text-rgb, 0, 0, 0), 0.3);
  }
  
  .discord-button {
    display: flex;
    align-items: center;
    background-color: rgba(88, 101, 242, 0.1); /* Subtle Discord blurple tint */
  }
  
  .discord-button:hover {
    background-color: var(--color-text);
    color: var(--color-primary);
  }
  
  .discord-logo {
    width: 20px;
    height: 20px;
    margin-right: 0.5rem;
  }
  
  .donate-button {
    display: flex;
    align-items: center;
    background-color: rgba(255, 87, 112, 0.1); /* Subtle Ko-fi red-pink tint */
  }
  
  .donate-button:hover {
    background-color: var(--color-text);
    color: var(--color-primary);
  }
  
  .kofi-logo {
    width: 20px;
    height: 20px;
    margin-right: 0.5rem;
  }
  
  /* ================================
     Hero Features Grid
     ================================ */
  .hero-features-container {
    width: 100%;
    margin-bottom: 3.5rem;
  }
  
  /* Add scroll margin for the "How It Works" section */
  #how-it-works {
    scroll-margin-top: 80px;
  }
  
  /* Updated grid to handle varying item counts */
  .feature-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
    width: 100%;
    max-width: 1200px;
  }
  
  .feature-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 1.5rem;
    background-color: rgba(255, 255, 255, 0.08);
    backdrop-filter: blur(8px);
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.15);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  }
  
  .feature-item:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.08);
  }
  
  .feature-icon {
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: rgba(var(--color-text-rgb, 0, 0, 0), 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-text);
    font-size: 1.2rem;
  }
  
  .feature-text {
    text-align: center;
  }
  
  .feature-highlight {
    display: block;
    font-weight: 700;
    font-size: 1.15rem;
    color: var(--color-text);
    margin-bottom: 0.5rem;
  }
  
  .feature-text p {
    margin: 0;
    color: var(--color-text);
    opacity: 0.85;
    font-size: 0.95rem;
    line-height: 1.5;
  }
  
  /* ================================
     Dark Theme Adjustments
     ================================ */
  body.dark .hero {
    background-image: linear-gradient(
      to bottom,
      var(--color-primary),
      var(--color-primary) 85%,
      var(--color-background) 100%
    );
  }
  
  body.dark .feature-item {
    background-color: rgba(0, 0, 0, 0.2);
    border-color: rgba(255, 255, 255, 0.1);
  }
  
  body.dark .feature-icon {
    background-color: rgba(255, 255, 255, 0.15);
  }
  
  body.dark .cta-button {
    color: var(--color-text);
    border-color: var(--color-text);
  }
  
  body.dark .cta-button:hover {
    background-color: var(--color-text);
    color: var(--color-primary);
  }
  
  /* ================================
     Responsive Adjustments
     ================================ */
  @media (max-width: 1100px) {
    .hero-headline {
      font-size: 2.8rem;
    }
    
    .hero-section-title {
      font-size: 1.8rem;
    }
    
    .feature-highlight {
      font-size: 1.1rem;
    }
  }
  
  @media (max-width: 900px) {
    .hero {
      padding-top: 2rem;
    }
    
    .hero-headline {
      font-size: 2.4rem;
      margin-bottom: 2.5rem;
    }
    
    .hero-section-title {
      font-size: 1.6rem;
      margin-bottom: 1.5rem;
    }
    
    .feature-grid {
      gap: 1.5rem;
    }
  }
  
  @media (max-width: 768px) {
    .hero {
      padding: 2rem 0;
      min-height: auto;
    }
    
    .hero-container {
      padding: 0 1.5rem;
    }
    
    .hero-logo {
      width: 150px;
    }
    
    .hero-headline {
      font-size: 2rem;
      margin-bottom: 1rem;
    }
    
    .hero-subtitle {
      font-size: 1.2rem;
      margin-bottom: 2rem;
    }
    
    .hero-section-title {
      font-size: 1.5rem;
    }
    
    .feature-grid {
      grid-template-columns: 1fr;
      gap: 1.5rem;
    }
    
    .feature-item {
      padding: 1rem;
    }
    
    .feature-icon {
      width: 28px;
      height: 28px;
    }
    
    .feature-highlight {
      font-size: 1.05rem;
    }
    
    .cta-container {
      flex-direction: column;
      align-items: center;
      gap: 1rem;
    }
    
    .cta-button {
      width: 100%;
      max-width: 260px;
      padding: 0.8rem 1rem;
    }
  }
  
  @media (max-width: 480px) {
    .hero-logo {
      width: 120px;
    }
    
    .hero-headline {
      font-size: 1.8rem;
    }
    
    .hero-subtitle {
      font-size: 1rem;
      margin-bottom: 1.5rem;
    }
    
    .hero-section-title {
      font-size: 1.3rem;
    }
    
    .feature-highlight {
      font-size: 1rem;
    }
    
    .feature-text p {
      font-size: 0.9rem;
    }
    
    .feature-icon {
      width: 24px;
      height: 24px;
      font-size: 0.85rem;
    }
  }