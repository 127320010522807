.privacy-policy {
  padding: 120px 20px 40px;
  min-height: calc(100vh - 60px);
  background-color: var(--color-background);
  color: var(--color-text);
}

.privacy-content {
  max-width: 800px;
  margin: 0 auto;
  text-align: left;
  position: relative; /* Add this */
}

.privacy-policy h1 {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  text-align: center;
}

.policy-section {
  margin-bottom: 2rem;
  text-align: left;
}

.policy-section h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: var(--color-text);
}

.policy-section p {
  line-height: 1.6;
  margin-bottom: 1rem;
}

.policy-section ul {
  list-style-type: disc;
  margin-left: 1.5rem;
  margin-bottom: 1rem;
}

.policy-section li {
  line-height: 1.6;
  margin-bottom: 0.5rem;
}

/* Dark mode styles */
body.dark .privacy-policy {
  background-color: var(--color-background);
  color: var(--color-text);
}

@media (max-width: 768px) {
  .privacy-policy {
    padding: 100px 15px 30px;
  }

  .privacy-policy h1 {
    font-size: 2.0rem;
  }

  .policy-section h2 {
    font-size: 1.3rem;
  }
}

.back-button {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 2rem;
  padding: 6px 12px;
  font-size: 0.9rem;
  border: 1px solid var(--color-text);
  border-radius: 6px;
  background: transparent;
  color: var(--color-text);
  transition: all 0.2s ease;
  font-family: inherit;
}

.back-button:hover {
  background: var(--color-text);
  color: var(--color-background);
  transform: translateX(-2px);
}

/* Update responsive styles */
@media (max-width: 768px) {
  .back-button {
    margin-bottom: 1.5rem;
    font-size: 0.85rem;
  }
}