/* src/components/Demo.css */
.demo {
  padding: 3rem 0;
  background-color: var(--color-background);
  color: var(--color-text);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease, color 0.3s ease;
  scroll-margin-top: 80px;
}

.demo-content {
  max-width: 800px;
  width: 100%;
  text-align: center;
  padding: 1rem;
}

.demo-title {
  font-size: 2.2rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  color: var(--color-text);
}

.demo-subtitle {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  color: var(--color-text);
  opacity: 0.8;
}

.player-wrapper {
  position: relative;
  padding-top: 59.47%; /* 3632:2160 aspect ratio */
  border: 2px solid var(--color-accent);
  border-radius: 8px;
  box-shadow: var(--shadow-md);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden;
}

.player-wrapper:hover {
  transform: scale(1.02);
  box-shadow: var(--shadow-lg);
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

/* Dark mode styles */
body.dark .demo {
  background-color: var(--color-background);
  color: var(--color-text);
}

body.dark .player-wrapper {
  border-color: var(--color-accent);
}